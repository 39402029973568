<template>
  <div class="my-5">
    <b-container>
      <h1 class="font-weight-bolder text-colorBlue text-center pb-2">
        Interpreter Services
      </h1>
      <h5 class="font-weight-bold">
        We provide Interpreting services that patients can communicate
        effectively with healthcare providers during medical tourism. We assist
        patients by translating medical records, explaining procedures, and
        facilitating real-time conversations with doctors, making entire process
        more comfortable and understandable for our patients.
      </h5>
      <!-- <b-table stacked="md" striped hover :items="interpreter" :fields="fields">
        <template #cell(description)="row">
          <p v-html="row.item.description"></p>
        </template>
        <template #cell(amount)="row">
          <div class="py-50">${{ row.item.amount }}</div>
        </template>
       <template #cell(manage)="row">
          <b-button
            :variant="isInCart(row.item) ? 'colorBlue' : 'colorGreen'"
            pill
            size="sm"
            class="mr-1"
            @click="handleCartAction(row.item)"
          >
            {{ isInCart(row.item) ? 'View Cart' : 'Add to Cart' }}
          </b-button>
        </template> 
      </b-table> -->
    </b-container>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import util from "@/util.js";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  mixins: [util],
  components: {
    ToastificationContent,
  },
  data() {
    return {
      interpreter: [],
      fields: [
        { key: "name", label: "Services Offered" },
        { key: "description", label: "Description" },
        { key: "location", label: "Location" },
        { key: "shift", label: "Shift" },
        { key: "amount", label: "Amount" },
      ],
    };
  },
  computed: {
    ...mapState({
      cart: (state) => state.appData.cart,
    }),
  },
  async mounted() {
    try {
      // this.initializeCart();

      const res = await this.getInterpratorServicePublic({});
      if (res.status === 200) {
        this.interpreter = res.data;
        console.log(this.interpreter);
      }
    } catch (error) {
      this.displayError(error);
    }
  },
  methods: {
    ...mapActions({
      getInterpratorServicePublic: "appData/getInterpratorServicePublic",
      addToCart: "appData/addToCart",
      removeFromCart: "appData/removeFromCart",
      // initializeCart: "appData/initializeCart",
    }),
    addCart(item) {
      this.addToCart({
        id: `${item.id}_${this.cartItemType.INTERPRETER}`,
        name: item.name,
        itemId: item.id,
        price: item.amount,
        type: this.cartItemType.INTERPRETER,
        quantity: 1,
        numberOfDays: 1,
      });
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Item added to cart successfully",
            variant: "success",
            icon: "BellIcon",
          },
        },
        {
          position: "top-right",
        }
      );
    },
    handleCartAction(item) {
      if (this.isInCart(item)) {
        this.$router.push({ name: "Cart" }); // Navigate to cart page
      } else {
        this.addCart(item);
      }
    },
    isInCart(item) {
      const cartItemId = `${item.id}_${this.cartItemType.INTERPRETER}`;
      return this.cart.some((cartItem) => cartItem.id === cartItemId);
    },
  },
};
</script>

<style scoped>
.bg-colorBlue:hover {
  background-color: #06bd46; /* Change to your desired green color */
}
</style>
